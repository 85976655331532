<div class="tippy-favorite-container">
	<div class="dialog-header">
		<div class="tool-container">
			<span class="header">Favorites</span>
		</div>
		<!-- <i class="bx bx-x bx-md close-icon" (click)="close()"></i> -->
	</div>
	<ul class="favorite-container-list">
		<div class="favorite-item" *ngFor="let favoriteMenuModel of favoriteMenuModelList; trackBy: trackByGroupId">
			<div style="padding-bottom: 2px; display: flex; gap: 6px; align-items: center">
				<img src="../../../../assets/images/Gold Star_2500px.png" height="20" width="20" />
				<span style="color: #8a7153; font-family: 'Lato-Bold'">{{ favoriteMenuModel.label }}</span>
			</div>
			<hr style="border: 1px solid #8a7153; opacity: 0.8" />
			<li class="favorite-item" *ngFor="let favorite of favoriteMenuModel.items; trackBy: trackByFavoriteMenuID">
				<!-- LINK FOR EXTERNAL MENU ITEM -->

				<a *ngIf="favorite.isExternal" class="favorite" (click)="close()" target="_blank" [href]="favorite.externalLink">{{ favorite.name }}</a>

				<!-- LINK FOR APPLICATION MENU ITEM -->
				<a
					*ngIf="!favorite.isExternal"
					class="favorite"
					(click)="close()"
					[routerLink]="['', favorite.isWikiFavorite ? favorite?.menuItem?.parentMenuGUID : favorite.menuItemURL]"
					[queryParams]="favorite.queryParams"
					>{{ favorite.name }}</a
				>
			</li>
		</div>
	</ul>
</div>
