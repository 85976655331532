import { Component, ElementRef, OnInit } from '@angular/core';
import { ToDoListComponent } from '../../stand-alone/admin/home/to-do-list/to-do-list.component';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { RoutePathResolverService } from '../../../services/route-path-resolver.service';
import { TippyCloseOnClickOutsideDirective } from '../../../../modules/directives/tippy-close-on-click-outside.directive';
import { ToDoEventModel } from '../../stand-alone/admin/home/to-do-list/shared/to-do-list.models';
import { ToDoService } from '../../../../modules/services/todo.service';
import { AppState } from '../../../../models/classes';
import { Store } from '@ngrx/store';
import { menuLoadedState } from '../../../../store/store.selector';
import { TippyComponentInstance } from '../../../../components/shared/enum';

@Component({
	standalone: true,
	imports: [ToDoListComponent, CommonModule, RouterModule, TippyCloseOnClickOutsideDirective],
	selector: 'app-my-day-popup',
	templateUrl: './my-day-popup.component.html',
	styleUrls: ['./my-day-popup.component.scss'],
})
export class MyDayPopupComponent implements OnInit {
	public viewComponentGUID!: string | undefined;
	public groupedToDoModelList: ToDoEventModel[] = [];
	viewToDoEvent: boolean = true;

	constructor(
		private elementRef: ElementRef,
		private tippyService: NgxTippyService,
		private router: Router,
		private routePathResolverService: RoutePathResolverService,
		private todoService: ToDoService,
		private store: Store<AppState>
	) {
		this.store.select(menuLoadedState).subscribe(async (x) => {
			if (x) {
				const response = await this.todoService.checkIfUserHasPermission();

				if (response.isSuccess && response.data) {
					this.viewToDoEvent = response.data[0];
				}
				this.groupedToDoModelList = [];

				if (this.viewToDoEvent) this.groupedToDoModelList = await this.todoService.prepareToDoListData();
			}
		});
	}

	ngOnInit() {}
	close() {
		this.tippyService.hide(TippyComponentInstance.MyDay);
		const userHistory = document.getElementsByClassName('user-history-container')[0];
		userHistory?.classList?.remove('pos-relative');
		const favComponent = document.getElementsByClassName('favorite-container')[0];
		favComponent?.classList?.remove('pos-relative');
	}

	viewAllMyDayList() {
		this.close();
		this.viewComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('ToDoEventComponent');
		this.router.navigate(['', this.viewComponentGUID]);
	}
}
