<h1 mat-dialog-title>Impersonate User</h1>

<div mat-dialog-content>
	<form [formGroup]="formGroup">
		<div class="row">
			<div>
				<div class="dx-fieldset" class="w-100">
					<div class="dx-field">
						<div class="dx-field-value" [className]="formGroup.get('email')?.hasError('required') && formGroup.get('email')?.touched ? 'validation-error' : ''">
							<!-- <dx-autocomplete placeholder="Enter name" [maxItemCount]="1000" 
								valueExpr="name" [(value)]="userName" [dataSource]="users"
								 formControlName="email"> </dx-autocomplete> -->

							<dx-select-box
								searchExpr="name"
								searchMode="contains"
								displayExpr="name"
								valueExpr="name"
								formControlName="email"
								[items]="users"
								[(value)]="userName"
								[searchEnabled]="true"
								searchMode="contains"
								[showDropDownButton]="false"
							>
							</dx-select-box>

							<span class="error-text">Please select a user</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<div mat-dialog-actions [align]="'end'">
	<button mat-raised-button (click)="onNoClick()" color="accent">Cancel</button>
	<button mat-raised-button (click)="save()" cdkFocusInitial color="primary">Ok</button>
</div>
