import { Injectable, OnDestroy } from '@angular/core';
import { PermissionService } from '../../../../goldstar-share/src/app/services/permission.service';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { UserPermissionLoadedAction } from '../store/store.actions';
import { userIdState } from '../store/store.selector';
import { Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})

/**
 * Observer wrapper for permission service
 */
export class InternalPermissionService implements OnDestroy {
	private userLoadedObserver$!: Subscription;

	constructor(private permissionService: PermissionService, private store: Store<AppState>) {
		// SUBSCRIBING TO USER LOADED EVENT
		this.userLoadedObserver$ = this.store.select(userIdState).subscribe(async (userId) => {
			if (userId) {
				this.init(userId);
			}
		});
	}

	/**
	 * Store observer initializer of permission service
	 * @param userEmail : current loggedIn user email
	 */
	async init(userEmail: string) {
		await this.permissionService.init(userEmail);
		this.store.dispatch(UserPermissionLoadedAction({ permissionList: this.permissionService.loadedUserPermissionList }));
	}

	/**
	 * On service destroy
	 */
	ngOnDestroy(): void {
		this.userLoadedObserver$.unsubscribe();
	}
}
