import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { InstancesChanges, NgxTippyService } from 'ngx-tippy-wrapper';
import { FavoriteMenuGroupModel, FavoriteMenuModel } from '../../models/models';
import { FavoriteMenuService } from '../../services/favorite-menu.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/classes';
import { favoriteMenuUpdated, menuLoadedState } from '../../../store/store.selector';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TippyCloseOnClickOutsideDirective } from '../../directives/tippy-close-on-click-outside.directive';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, TippyCloseOnClickOutsideDirective],
	selector: 'app-side-navbar',
	templateUrl: './side-navbar.component.html',
	styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	public menuLoadedObserver$!: Subscription;
	public favoriteMenuUpdatedObserver$!: Subscription;

	public favoriteMenuModelList: FavoriteMenuGroupModel[] = [];
	private ngUnSubscribe$ = new Subject<void>();
	constructor(
		private menuService: FavoriteMenuService,
		private el: ElementRef,
		private tippyService: NgxTippyService,
		private store: Store<AppState>
	) {}

	async ngOnInit(): Promise<void> {
		//await this.initializeSubscription();
	}
	trackByGroupId(index: number, item: FavoriteMenuGroupModel) {
		return item.id ?? index;
	}
	trackByFavoriteMenuID(index: number, item: FavoriteMenuModel) {
		return item.id ?? index;
	}
	async initializeSubscription() {
		this.store
			.select(menuLoadedState)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe((x) => {
				if (x) {
					this.loadFavList();
				}
			});

		// SUBSCRIPTION WHEN FAVORITE MENU IS UPDATED
		this.store
			.select(favoriteMenuUpdated)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe((x) => {
				if (x) {
					this.loadFavList();
				}
			});
	}

	async loadFavList() {
		this.favoriteMenuModelList = (await this.menuService.prepareUserFavoriteMenuGroup('')).filter((x) => x.items.length != 0);
	}
	async ngAfterViewInit(): Promise<void> {
		await this.initializeSubscription();
	}

	ngOnDestroy(): void {
		this.ngUnSubscribe$.next();
		this.ngUnSubscribe$?.unsubscribe();
	}

	close() {
		this.tippyService.hide('instance-12');
	}
}
