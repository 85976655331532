<div class="feedback">
	<div class="feedback-box dropdown-menu show" aria-labelledby="feedbackDropdown" data-bs-popper="none">
		<form [formGroup]="feedbackForm">
			<div class="my-day-header">
				<h4 class="feedback-header-text">
					Questions, Comments & Suggestions<span><img (click)="close()" src="../../../../../assets/images/modal-close.png" alt="" /></span>
				</h4>
			</div>
			<div class="my-day-body">
				<div class="feedback-block">
					<div class="radio-field">
						<dx-radio-group displayExpr="systemCode" [items]="feedbackTypeList" formControlName="feedbackType" layout="horizontal" (onValueChanged)="updatePlaceholder($event)">
						</dx-radio-group>
					</div>
					<div [className]="feedbackForm.get('feedbackContent')?.hasError('required') && feedbackForm.get('feedbackContent')?.touched ? 'validation-error' : ''">
						<dx-text-area height="70" formControlName="feedbackContent" maxLength="256" [placeholder]="placeholderText"> </dx-text-area>
						<i class="fa fa-exclamation-circle error-symbol error-symbol-textarea" aria-hidden="true"></i>
						<span class="error-text">{{ requiredMessage + ' is required' }}</span>
					</div>
				</div>
				<div class="btn-save-feedback">
					<button (click)="saveFeedbackDetails()" [disabled]="isSendButtonDisabled" class="btn-send btn-performance-save">Send</button>
				</div>
				<div *ngIf="hasViewFeedbackPermission" class="link-history">
					<a (click)="viewHistory()"><u>View History</u></a>
				</div>
			</div>
		</form>
	</div>
</div>
