<ul class="top-navbar" [ngClass]="!sideBarExpanded ? 'collapsed-height' : 'expanded-height'">
	<li class="top-navbar-item-container dashboard-container">
		<div class="arrow-right" [ngClass]="!sideBarExpanded ? 'flex-container' : 'hidden'" (click)="toggleSideNav()">
			<i class="bx bx-chevrons-right bx-xs" [ngClass]="title === 'Dashboard' ? 'toggle' : 'toggle-padded'"></i>
		</div>
		<!-- <i class="bx toggle" [ngClass]="togglerIcon" (click)="toggleSideNav()"></i> -->
		<a [routerLink]="['']" class="nav-bar-item header-name">{{ title }}</a>
	</li>
	<!-- <li class="top-navbar-item-container search-list-item">
		<form action="" class="search-container">
			<input type="search" class="search" placeholder="Search here" />
			<i class="bx bx-search-alt bx-xs search-icon"></i>
		</form>
	</li> -->
	<li class="top-navbar-item-container right-container">
		<!-- <a class="nav-bar-item right-items">
			<i class="bx bxs-cog bx-xs icon"></i> 
			<img src="../../../../assets/images/Trb-latest-svg/Icon-settings.svg" height="18" />
		</a> -->
		<a *ngIf="hasFeedbackPermission" [ngClass]="{'nav-bar-item': true, 'right-items': true, 'tippy-button': true, 'suggestion-box': hasSuggestions}"  title="Suggestion Box" tippyName="Suggestion Box" (click)="showFeedbackPopup()">
			<img src="../../../../assets/images/suggestion-icon.png" height="18" alt="" />
		</a>

		<a class="nav-bar-item right-items tippy-button" title="Favorites" tippyName="instance-12" [ngxTippy]="favoriteComponent" [tippyProps]="tippyProps" (click)="showFavoritePopup()">
			<!-- <i class="bx bxs-star bx-xs icon"></i> -->
			<img src="../../../../assets/images/Trb-latest-svg/Icon-star.svg" height="18" alt="" />
		</a>
		<!-- <a href="" class="nav-bar-item right-items">
			<i class="bx bxs-message bx-xs icon"></i>
		</a> -->
		<a
			class="nav-bar-item right-items"
			title="My Day"
			tippyName="my-day-popup-instance"
			[ngxTippy]="isMyDayInitialized ? myDayPopupComponent : null"
			[tippyProps]="tippyProps"
			(click)="showMyDayPopup()"
		>
			<!-- <i class="bx bxs-calendar-star bx-xs icon"></i> -->
			<img src="../../../../assets/images/Trb-latest-svg/checklist (1).svg" height="18" alt="" />
		</a>
		<!-- <a class="nav-bar-item right-items">
			<i class="bx bxs-bell bx-xs icon"></i>
			<img src="../../../../assets/images/Trb-latest-svg/Icon-bell.svg" height="18" />
		</a> -->

		<!-- HAMBURGER MENU FOR THE TOP NAVBAR ITEMS -->
		<!-- <div class="dropdown hamburger-menu-container">
			<a class="app-dropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				<i class="bx bx-menu bx-md"></i>
			</a>

			<ul class="dropdown-menu menu" aria-labelledby="dropdownMenuButton1">
				<li class="item-container" *ngFor="let navigationMenu of navigationMenuList">
					<ng-container *ngIf="navigationMenu.name == 'Favorites'">
						<a class="dropdown-item" tippyName="instance-13" [ngxTippy]="componentComponent" [tippyProps]="tippyProps" (click)="showFavoritePopupCollapsed()">
							<img src="../../../../assets/images/Trb-latest-svg/Icon-star.svg" height="18" />
							<span class="dropdownContent">{{ navigationMenu.name }}</span>
						</a>
					</ng-container>
					<ng-container *ngIf="navigationMenu.name != 'Favorites'">
						<a class="dropdown-item" (click)="navigationMenu.onClick(navigationMenu.name)">
							<i class="bx bx-sm profile-icon" [ngClass]="navigationMenu.icon"></i>
							<span class="dropdownContent">{{ navigationMenu.name }}</span>
						</a>
					</ng-container>
				</li>
			</ul>
		</div> -->

		<!-- USER PROFILE MENU -->
		<div class="dropdown profile-dropdown">
			<button class="btn btn-secondary dropdown-toggle app-dropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				<span class="profile-avatar-container" style="object-fit: cover">
					<img style="border-radius: 50%; object-fit: contain" height="30" width="30" [src]="userProfileImage" alt="Profile Picture" />
				</span>
				<span class="profile-name"> {{ loggedInUserName }}</span>
			</button>

			<!--THE PROFILE MENU ARE PREPARED DYNAMICALLY  -->
			<ul class="dropdown-menu menu" aria-labelledby="dropdownMenuButton1">
				<li class="item-container" *ngFor="let profileMenu of profileMenuList">
					<a class="dropdown-item" (click)="profileMenu.onClick(profileMenu.name)">
						<i class="bx bx-sm profile-icon" [ngClass]="profileMenu.icon"></i>
						<span class="dropdownContent">{{ profileMenu.name }}</span>
					</a>
				</li>
			</ul>
		</div>
	</li>
</ul>
