import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { dirtySaveActionState } from '../../store/store.selector';
import { Store } from '@ngrx/store';
import { ConfirmationPopupService } from '../services/confirmation-popup.service';

let hasUnsavedChanges: boolean = false;
export const DirtySaveGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router);
	const store = inject(Store);
	const confirmationPopUpService = inject(ConfirmationPopupService);

	store.select(dirtySaveActionState).subscribe((hasUnSavedChanges: boolean) => {
		hasUnsavedChanges = hasUnSavedChanges ?? false;
	});

	const urlWithoutQueryParams = getUrlWithoutQueryParams(state.url);
	const queryParams = {
		queryParams: route.queryParams ? route.queryParams : undefined,
	};
	if (hasUnsavedChanges) {
		confirmationPopUpService.showConfirmationModal({
			title: '',
			message: '',
			onConfirm: () => {
				router.navigate([urlWithoutQueryParams], queryParams);
			},
		});
		return false;
	} else {
		return true;
	}
};

const getUrlWithoutQueryParams = (fullUrl: string) => {
	const parts = fullUrl.split('?');
	const urlWithoutQueryParams = parts[0];
	return urlWithoutQueryParams;
};
