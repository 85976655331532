import { Component, OnInit } from '@angular/core';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { RoutePathResolverService } from '../../../services/route-path-resolver.service';
import { TippyCloseOnClickOutsideDirective } from '../../../../modules/directives/tippy-close-on-click-outside.directive';
import { AppState } from '../../../../models/classes';
import { Store } from '@ngrx/store';
import { StandAloneDxModule } from '../../../../utilities/stand-alone-dx/stand-alone-dx.module';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackOptions, FeedbackPlaceholder } from '../../stand-alone/feedback/enum';
import { FeedbackService } from '../../stand-alone/feedback/services/feedback.service';
import { FeedbackRequest, FeedbackTypeItem, InternalTitlePermissionListItem } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { UserInfoService } from '../../../../services/user-info.service';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { PermissionService } from '../../../../../../../goldstar-share/src/app/services/permission.service';
import { userPermissionLoaded } from '../../../../../app/store/store.selector';
import { Subscription } from 'rxjs';
import { TippyComponentInstance } from '../../../../components/shared/enum';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, TippyCloseOnClickOutsideDirective, StandAloneDxModule, FormsModule, ReactiveFormsModule],
	selector: 'app-feedback-popup',
	templateUrl: './feedback-popup.component.html',
	styleUrls: ['./feedback-popup.component.scss'],
})
export class FeedbackPopupComponent implements OnInit {
	public viewFeedbackComponentGUID!: string | undefined;
	public feedbackTypeList: FeedbackTypeItem[] = [];
	placeholderText = FeedbackPlaceholder.PH_QUESTION;
	requiredMessage = '';
	feedbackForm: FormGroup;
	hasViewFeedbackPermission!: boolean;
	public userPermissionLoaded$!: Subscription;
	isSendButtonDisabled: boolean = false;
	public readonly viewFeedbackPermissionRoute: string = '/dashboard/view-question-comment-suggestion';
	defaultValue!: FeedbackTypeItem;

	constructor(
		private fb: FormBuilder,
		private feedbackService: FeedbackService,
		private tippyService: NgxTippyService,
		private router: Router,
		private routePathResolverService: RoutePathResolverService,
		private userInfoServices: UserInfoService,
		private store: Store<AppState>,
		private errorHandlingService: ErrorHandlingService,
		private permissionService: PermissionService
	) {
		this.feedbackForm = this.fb.group({
			feedbackType: [null],
			feedbackContent: [null, [Validators.required, this.feedbackService.trimValidator()]],
		});
	}

	async ngOnInit() {
		await this.initializeSubscriptions();
		const response = await this.feedbackService.fetchAllFeedbackTypes();
		if (response.isSuccess) {
			this.feedbackTypeList = response.data ?? [];

			if (this.feedbackTypeList.length > 0) {
				this.defaultValue = this.feedbackTypeList[0];
				this.feedbackForm.controls.feedbackType.setValue(this.defaultValue);
			}
		}
	}

	async initializeSubscriptions() {
		// SUBSCRIBING TO USER PERMISSION LOADED
		this.userPermissionLoaded$ = this.store.select(userPermissionLoaded).subscribe(async (_allPermission: InternalTitlePermissionListItem[] | undefined) => {
			if (_allPermission) {
				this.hasViewFeedbackPermission = await this.permissionService.userHasPermission(this.viewFeedbackPermissionRoute);
			}
		});
	}

	async saveFeedbackDetails() {
		const isFormValid = this.feedbackForm.valid;
		if (isFormValid) {
			this.isSendButtonDisabled = true;
			let currentUser = await this.userInfoServices.getCurrentLoggedInUser();

			let feedbackRequest: FeedbackRequest = {
				content: this.feedbackForm.controls.feedbackContent.value,
				createdByInternalUserGUID: currentUser.internalUserGUID,
				feedbackTypeGUID: this.feedbackForm.controls.feedbackType.value.feedbackTypeGUID,
			};
			let feedbackTypeLabel = this.feedbackTypeList.find((x) => x.feedbackTypeGUID == feedbackRequest.feedbackTypeGUID)?.systemCode ?? '';
			const saveResponse = await this.feedbackService.saveFeedback(feedbackRequest);

			if (saveResponse.isSuccess) {
				this.feedbackService.updatedFeedbackItem.next(feedbackRequest);

				this.close();
				this.errorHandlingService.showSuccessMessage(`${feedbackTypeLabel} saved successfully.`, false);
			} else {
				this.errorHandlingService.showErrorMessage('Error saving data.', false);
			}
			this.isSendButtonDisabled = false;
		} else {
			this.feedbackForm.markAllAsTouched();
		}
	}

	close() {
		this.tippyService.hide(TippyComponentInstance.Feedback);
		this.feedbackForm.reset();
		// Reset radio button selection to the first option
		this.feedbackForm.patchValue({
			feedbackType: this.defaultValue,
		});
		// Update placeholder text to default based on the first option
		this.placeholderText = FeedbackPlaceholder.PH_QUESTION;

		// const userHistory = document.getElementsByClassName('user-history-container')[0];
		// userHistory?.classList?.remove('pos-relative');
		// const favComponent = document.getElementsByClassName('favorite-container')[0];
		// favComponent?.classList?.remove('pos-relative');
	}

	updatePlaceholder(event: any): void {
		let optionVal = event.value;
		this.requiredMessage = optionVal?.systemCode;
		switch (optionVal?.systemCode) {
			case FeedbackOptions.QUESTION:
				this.placeholderText = FeedbackPlaceholder.PH_QUESTION;
				break;
			case FeedbackOptions.COMMENT:
				this.placeholderText = FeedbackPlaceholder.PH_COMMENT;
				break;
			case FeedbackOptions.SUGGESTION:
				this.placeholderText = FeedbackPlaceholder.PH_SUGGESTION;
				break;
			default:
				this.placeholderText = FeedbackPlaceholder.PH_QUESTION;
				break;
		}
	}

	viewHistory() {
		this.close();
		this.viewFeedbackComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('ViewFeedbackComponent');
		this.router.navigate(['', this.viewFeedbackComponentGUID]);
	}
}
