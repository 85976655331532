import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DashboardMenuService } from '../../services/dashboard-menu.service';
import { MenuGroupUIModel, MenuUIModel } from '../../models/models';
import { AppState } from '../../../models/classes';
import { Store } from '@ngrx/store';
import { menuGroupUpdated, menuLoadedState, userIdState, userPermissionLoaded, favoriteMenuUpdated } from '../../../store/store.selector';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../components/auth/auth.service';
import { MenuService } from '../../../services/menuService';
import { MenuGroupModel } from '../../../models/models';
import { BehaviorSubject, Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { InternalPermissionService } from '../../../services/internal-permission.service';
import { InternalTitlePermissionListItem } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { UserRecentService } from '../../../services/user-recent.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
	public userName!: string;
	public lastSelectedMenuGroupIndex!: number;

	// These are container width expressed in rem
	public sideBarContainerWidth: number = 17;
	public togglePositionRight!: string;
	sidebarExpanded: boolean = true;
	mainContainerWidth!: string;
	marginLeft = '20rem';
	mainContentContainerStyle: any;
	menuGroupModelList: MenuGroupUIModel[] = [];

	selectedMenuGroupModel!: MenuGroupUIModel | null;

	// Subscriptions
	public menuLoaded$!: Subscription;
	public userAuthenticated$!: Subscription;
	public menuGroupUpdated$!: Subscription;
	public userPermissionLoaded$!: Subscription;
	public menuContainerSpinner: string = 'menuContainerSpinner';
	public menuLoaded!: boolean;

	public pageLevelSpinner: string = 'pageLevelSpinner';
	public componentLoaded!: boolean;

	public searchTerm!: string;
	public searchInProgress!: boolean;
	private search$ = new BehaviorSubject('');
	private destroy$ = new Subject<void>();
	elementIdPrefix: string = 'dashboard-';

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		this.mainContainerWidth = this.computeMainContainerWidth(window.innerWidth);
		this.marginLeft = this.computeMainContainerMargin();
		this.mainContentContainerStyle = {
			width: `${this.mainContainerWidth}`,
			'margin-left': `${this.marginLeft}`,
		};
	}

	constructor(
		private menuService: DashboardMenuService,
		private store: Store<AppState>,
		private spinnerService: NgxSpinnerService,
		private authService: AuthService,
		private internalMenuServices: MenuService,
		private permissionService: InternalPermissionService,

		public userRecentMenu: UserRecentService
	) {}

	async ngOnInit(): Promise<void> {
		this.lastSelectedMenuGroupIndex = 0;
		this.componentLoaded = false;
		this.spinnerService.show(this.pageLevelSpinner);
		this.initializeSubscriptions();
	}

	async initializeSubscriptions() {
		// SUBSCRIBING TO USER UPDATE EVENT POST SUCCESSFUL AUTHENTICATION
		this.userAuthenticated$ = this.store.select(userIdState).subscribe(async (userId) => {
			if (userId) {
				this.menuLoaded = false;
				this.spinnerService.show(this.menuContainerSpinner);
				this.lastSelectedMenuGroupIndex = 0;
				this.authService.emailAddress = userId;
				this.authService.userId = userId;
				await this.initializeComponent();
			}
		});

		// SUBSCRIBING TO USER PERMISSION LOADED
		this.userPermissionLoaded$ = this.store.select(userPermissionLoaded).subscribe((_allPermission: InternalTitlePermissionListItem[] | undefined) => {
			// ONLY LOAD MENUS WHEN USER PERMISSION HAVE BEEN LOADED
			if (_allPermission) {
				this.internalMenuServices.initialize();
			}
		});

		// SUBSCRIBING TO MENU LOADED STATE
		this.menuLoaded$ = this.store.select(menuLoadedState).subscribe(async (menuLoaded) => {
			if (menuLoaded) {
				await this.initializeMenu();
				this.menuLoaded = true;
				this.searchTerm = '';
				this.spinnerService.hide(this.menuContainerSpinner);
			}
		});

		// SUBSCRIBING TO MENU GROUP UPDATED STATE
		this.menuGroupUpdated$ = this.store.select(menuGroupUpdated).subscribe(async (menuGroups: MenuGroupModel[]) => {
			await this.initializeMenu();
		});
		// SUBSCRIBING TO SEARCH EVENT
		this.search$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((search) => this.searchMenu(search));
	}

	async initializeComponent() {
		this.mainContainerWidth = this.computeMainContainerWidth(window.innerWidth);
		this.marginLeft = this.computeMainContainerMargin();
		this.mainContentContainerStyle = {
			width: `${this.mainContainerWidth}`,
			'margin-left': `${this.marginLeft}`,
		};
		this.componentLoaded = true;
		this.spinnerService.hide(this.pageLevelSpinner);
	}

	async initializeMenu() {
		this.menuGroupModelList = await this.menuService.prepareDashboardMenu();
		this.selectedMenuGroupModel = this.menuGroupModelList.length >= 1 ? this.menuGroupModelList[this.lastSelectedMenuGroupIndex] : null;
		this.selectedMenuGroupModel ? (this.selectedMenuGroupModel.selected = true) : false;
	}

	/**
	 * Event bubbling prevention
	 * @param event
	 */
	searchPlaceHolderClick(event: any): void {
		this.expandSideNavar();
		event?.stopPropagation();
	}

	/**
	 * Search keyup event
	 * @param event
	 */
	async searchKeyUp(event: KeyboardEvent): Promise<void> {
		this.searchInProgress = true;
		this.search$.next(this.searchTerm);
	}

	/**
	 * Search MenuGroups and Menus
	 * @param searchTerm
	 * @returns
	 */
	async searchMenu(searchTerm: string): Promise<void> {
		if (searchTerm == '') return this.clearSearch();
		this.lastSelectedMenuGroupIndex = 0;
		this.menuGroupModelList = await this.menuService.searchMenu(searchTerm);
		this.selectedMenuGroupModel = this.menuGroupModelList.length >= 1 ? this.menuGroupModelList[this.lastSelectedMenuGroupIndex] : null;
		this.selectedMenuGroupModel ? (this.selectedMenuGroupModel.selected = true) : false;
	}

	/**
	 * Clear search criteria
	 */
	async clearSearch() {
		// Clear search
		this.searchInProgress = false;
		this.lastSelectedMenuGroupIndex = 0;
		this.searchTerm = '';
		this.menuGroupModelList = await this.menuService.prepareDashboardMenu();
		this.selectedMenuGroupModel = this.menuGroupModelList.length >= 1 ? this.menuGroupModelList[this.lastSelectedMenuGroupIndex] : null;
		this.selectedMenuGroupModel ? (this.selectedMenuGroupModel.selected = true) : false;
	}

	toggleSideNav() {
		this.sidebarExpanded = !this.sidebarExpanded;
		this.sideBarContainerWidth = this.sidebarExpanded ? 17 : 6;
		this.mainContainerWidth = this.computeMainContainerWidth(window.innerWidth);
		this.marginLeft = this.computeMainContainerMargin();
		this.mainContentContainerStyle = {
			width: `${this.mainContainerWidth}`,
			'margin-left': `${this.marginLeft}`,
		};
	}

	/**
	 * Toggle isFavorite for a menu
	 * @param menuUIModel
	 */
	async markMenuAsFavorite(menuUIModel: MenuUIModel) {
		if (menuUIModel) {
			menuUIModel.menuItem.isFavorite = menuUIModel.menuItem.isFavorite ? !menuUIModel.menuItem.isFavorite : true;
			this.menuService.markMenuAsFavorite(menuUIModel.menuItem);
		}
	}

	expandSideNavar() {
		this.sidebarExpanded = true;
		this.sideBarContainerWidth = this.sidebarExpanded ? 17 : 6;
		this.mainContainerWidth = this.computeMainContainerWidth(window.innerWidth);
		this.marginLeft = this.computeMainContainerMargin();
		this.mainContentContainerStyle = {
			width: `${this.mainContainerWidth}`,
			'margin-left': `${this.marginLeft}`,
		};
	}

	computeMainContainerWidth(viewPortWidth: number): string {
		const widthInPixel = this.sideBarContainerWidth * 16;
		const mainContainerWidth = viewPortWidth - widthInPixel;
		const widthInRem = mainContainerWidth / 16;
		this.togglePositionRight = `${widthInRem - 1.9}rem`;
		return `${widthInRem}rem`;
	}

	computeMainContainerMargin(): string {
		const containerMargin = `${this.sideBarContainerWidth}rem`;
		return containerMargin;
	}

	onMenuItemSelected(allMenuModels: MenuGroupUIModel[], selectedMenuModel: MenuGroupUIModel) {
		const allMenuModelsExceptSelectedOne = allMenuModels.filter((x) => x.name != selectedMenuModel.name);
		allMenuModelsExceptSelectedOne.forEach((x) => (x.selected = false));
		selectedMenuModel.selected = true;
		this.onMenuGroupSelected(selectedMenuModel);
	}

	onMenuGroupSelected(menuGroupModel: MenuGroupUIModel) {
		this.lastSelectedMenuGroupIndex = this.menuGroupModelList.indexOf(menuGroupModel);
		this.selectedMenuGroupModel = menuGroupModel;
		this.sidebarExpanded = false;
		this.expandSideNavar();
	}

	showImmediateChild(data: MenuUIModel) {
		data.childVisible = true;
		data.items.forEach((x) => (x.nestedChild = false));
	}

	hideImmediateChild(data: MenuUIModel) {
		data.childVisible = false;
	}

	onExternalMenuClicked(menuUIModel: MenuUIModel) {
		this.userRecentMenu.addUserRecentMenu(menuUIModel.menuItem);
	}

	ngOnDestroy(): void {
		this.disposeSubscriptions();
	}

	disposeSubscriptions() {
		this.userAuthenticated$.unsubscribe();
		this.userPermissionLoaded$.unsubscribe();
		this.menuLoaded$.unsubscribe();
		this.menuGroupUpdated$.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
